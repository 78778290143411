@use "./src/base";

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    width: 90%;
    max-width: 600px;
    margin: 50px;
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    margin-top: 90px;
}

.enableShelterHouseSwitch {
    width: fit-content;
    padding: 5px 5px 5px 20px;
    border-radius: 100px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    color: base.$darkText;
    cursor: pointer;
    font-weight: 400;
}

.enableShelterHouse {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 20px;
    text-align: center;

    .sectionTitle {
        color: white;
        margin-top: 50px;
        margin-bottom: 10px;
        text-align: center;
    }

    .sectionSubTitle {
        color: white;
        margin-bottom: 30px;
        text-align: center;
        font-size: 18px;
    }

    i {
        margin-top: 150px;
        font-size: 80px;
    }

}

.shelterActiveContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .editShelter {
        align-self: flex-end;
        background-color: white;
        border-radius: 100px;
        color: base.$darkPrimary;
        margin-top: 100px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .shelterActiveContainerHeader {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;

        .imageContainer {
            width: 150px;
            height: 150px;
            min-width: 150px;
            min-height: 150px;
            border-radius: 100px;
            overflow: hidden;
            border-style: dashed;
            border-color: white;
            border-radius: 100px;
            padding: 5px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 100px;
                object-fit: cover;
            }
        }

        .shelterTexts {
            display: flex;
            flex-direction: column;
            margin-left: 50px;
            color: white;

            h1 {
                margin-bottom: 10px;
            }

            h3 {
                font-weight: 300;
            }

            .address {
                width: fit-content;
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                gap: 10px;
                background-color: white;
                color: black;
                padding: 5px 20px;
                border-radius: 25px;

                h3 {
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .phone {
                font-weight: 400;
                width: fit-content;
                font-size: 14px;
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                background-color: white;
                color: black;
                padding: 5px 20px;
                border-radius: 25px;
            }
        }

    }

}

.shareContainer{
    margin-top: 50px;
    color: white;
    cursor: pointer;
    width: fit-content;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-self: flex-end;
    background-color: base.$primary;
    z-index: 1;
    padding: 10px 20px;
    border-radius: 25px;
    border: 2px solid white;
}


.lineHeader {
    background-color: white;
    width: 100%;
    height: 1px;
    margin-top: 80px;
    margin-bottom: 80px;
}

.addPetButton {
    background-color: white;
    border-radius: 100px;
    cursor: pointer;
    align-self: flex-end;
    color: base.$darkPrimary;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        margin: auto;
    }
}

.sectionTitle {
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}



.petsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .petsCellContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 25px;
        padding: 30px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;
        margin-bottom: 15px;

        .petsCellTagsContainer {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: start;
            margin-bottom: 10px;
            align-items: center;
        }
        
        .petsCellTagsContainer p {
            color: base.$darkText;
            font-size: 18px;
            margin-left: 15px;
            font-weight: 300;
        }
        
        .petsCellTagsContainer i {
            color: base.$lightGray;
            align-self: center;
            margin: 0px;
            margin-bottom: 2px;
            font-size: 18px;
        }
        
        .cardIconContainers{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        
        .iconText {
            width: fit-content;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-bottom: 10px;
            margin-top: 20px;
            align-items: center;
            color: base.$primary;
            gap: 10px;
            cursor: pointer;
        }
        
        
        .line{
            height: 2px;
            background-color: base.$lightGray;
            border-radius: 25px;
            margin-top: 10px;
        }
        
        .comment{
            padding: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color:  base.$darkText;
            font-size: 18px;
            gap: 10px;
        }
        
        
        .postTitle {
            font-size: 22px;
            margin-right: 20px;
        }
        
        .postDescription {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    
        .gallery {
            margin-top: 20px;
            background-color: black;
            border-radius: 10px;
            overflow: hidden;
        }
        
        .postTitleContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        
        .postTitleContainer i {
            font-size: 22px;
            color: base.$darkPrimary;
            cursor: pointer;
        }
    }


}

.gallery img {
    width: 100%;
    height: 350px;
    overflow: hidden;
    object-position: center center;
}


.emptyContainer {
    margin-top: 200px;
    margin-bottom: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-size: 24px;
    background-color: base.$primary;
    border-radius: 25px;

    h2 {
        text-align: center;
    }

    i {
        font-size: 50px;
        margin-bottom: 15px;
    }
}

.loadMoreContainer {
    background-color: base.$primary;
    width: 250px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    align-self: center;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 50px;
    margin-top: 30px;
    border: 1px solid white;
}


@media only screen and (max-width: 600px) {

    .shelterActiveContainer {

        .shelterActiveContainerHeader {
            flex-direction: column;

            .imageContainer {
                margin-bottom: 50px;
                width: 200px;
                height: 200px;
            }
        }
    }

    .shareContainer{
        align-self: center;
    }

}