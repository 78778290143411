@use "./src/base";

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    width: 90%;
    max-width: 600px;
    margin: 50px;
    background-color: white;
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    margin-top: 90px;
}

.loginHeaderImage {
    align-self: center;
    width: 300px;
    height: 300px;
}

.loginTitle {
    text-align: center;
    margin: 0px;
    margin-bottom: 30px;
    font-size: 42px;
}

.loginBottonButton {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.primaryButton {
    border-radius: 30px;
    height: 60px;
    border: 0px solid;
    color: white;
    background-color: base.$primary;
    font-size: 24px;
}

.primaryButton:active {
    border-radius: 25px;
    border: 0px solid;
    color: white;
    background-color: base.$darkPrimary;
}

.createAccountText {
    width: 80%;
    font-size: 22px;
    margin: 0;
    font-weight: 400;
    color: base.$darkText;
    padding-top: 15px;
    text-align: center;
    align-self: center;
}

.primaryButton:hover {
    cursor: pointer;
}

.createAccountText:hover {
    cursor: pointer;
}


.gallery {
    background-color: black;
    margin-bottom: 20px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.deleteIcon {
    color: base.$red;
    margin: 0px;
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
}

.petImageDelete {
    display: flex;
    flex-direction: row;
    background-color: base.$primary;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 50;
}

.petImageDelete i {
    margin: 0px;
    font-size: 22px;
    color: white;
}

.hintMultiplePictures{
    font-weight: 200;
    margin-bottom: 5px;
}


@media only screen and (max-width: 600px) {


    .loginTitle {
        font-size: 28px;
    }

    .loginHeaderImage {
        width: 200px;
        height: 200px;
    }

    .createAccountText {
        font-size: 16px;
    }

    .primaryButton {
        font-size: 16px;
        height: 45px;
    }

    .mainContainerInside {
        margin-top: 90px;
    }

    .petImageDelete {
        width: 40px;
        height: 40px;
    }

    .petImageDelete i {
        font-size: 16px;
    }

    .deleteIcon {
        font-size: 18px;
    }

    .mainContainerInside {
        margin-top: 90px;
    }

}