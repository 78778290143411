@use "./src/base";

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    margin-top: 90px;
    width: 90%;
    max-width: 600px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.myPetsHeader {
    background-color: white;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    cursor: pointer;
}

.myPetsHeader i {
    margin: 0px;
    font-size: 30px;
}

.myPetsHeader h3 {
    margin: 0px;
    font-size: 30px;
}


.lookDogs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: 2px solid white;
    border-radius: 25px;
    color: white;
    padding: 10px 20px;
    width: max-content;
    cursor: pointer;
    gap: 20px;
    font-size: 12px;
    align-self: flex-end;
}


.lookDogs .movei {
    margin-left: 0px;
    margin-right: 10px;
    transition: 0.3s;
}

.lookDogs:hover .movei {
    margin-right: 0px;
    margin-left: 10px;
    transition: 0.3s;
}


.lookDogs img {
    margin-left: 10px;
    width: 20px;
    height: 20px;
}


.petsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.petsCellContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 25px;
    padding: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 0px;
    margin-bottom: 15px;
}


.petsCellTagsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    margin-bottom: 10px;
    align-items: center;
}

.petsCellTagsContainer p {
    color: base.$darkText;
    font-size: 20px;
    margin-left: 15px;
    font-weight: 300;
}

.petsCellTagsContainer i {
    color: base.$lightGray;
    align-self: center;
    margin: 0px;
    margin-bottom: 2px;
    font-size: 20px;
}

.cardIconContainers{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.iconText {
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 20px;
    align-items: center;
    color: base.$primary;
    gap: 10px;
    cursor: pointer;
}


.line{
    height: 2px;
    background-color: base.$lightGray;
    border-radius: 25px;
    margin-top: 10px;
}

.comment{
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color:  base.$darkText;
    font-size: 18px;
    gap: 10px;
}


.postTitle {
    font-size: 24px;
    margin-right: 20px;
}

.postDescription {
    margin-top: 20px;
    margin-bottom: 20px;
}

.sectionTitle {
    color: white;
    margin-top: 60px;
    margin-bottom: 30px;
    text-align: center;
}

.gallery {
    margin-top: 20px;
    background-color: black;
    border-radius: 10px;
    overflow: hidden;
}

.gallery img {
    width: 100%;
    height: 400px;
    overflow: hidden;
    object-position: center center;
}

.shelterContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;

    img{
        width: 50px;
        height: 50px;
        border-radius: 100px;
    }

    h2{
        margin-left: 10px;
        font-weight: 600;
    }
}

.postTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    i {
        font-size: 22px;
        color: base.$darkPrimary;
        cursor: pointer;
    }
}


.filterHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
}

.myPostButton {
    background-color: white;
    border-radius: 25px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.searchContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 25px;
    padding: 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.searchButton {
    background-color: base.$primary;
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    align-self: center;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.emptyContainer {
    margin-top: 200px;
    margin-bottom: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-size: 24px;
    background-color:  base.$primary;
    border-radius: 25px;
}

.emptyContainer h2 {
    text-align: center;
}

.emptyContainer i {
    font-size: 50px;
    margin-bottom: 15px;
}

.loadMoreContainer {
    background-color: base.$primary;
    width: 250px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    align-self: center;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 50px;
    margin-top: 30px;
    border: 1px solid white;
}


@media only screen and (max-width: 600px) {


    .myPetsHeader h3 {
        font-size: 22px;
    }

    .postTitle {
        font-size: 22px;
    }

    .petsCellTagsContainer p {
        font-size: 18px;
        margin-left: 0px;
    }

    .petsCellTagsContainer i {
        font-size: 18px;
        margin-right: 10px;
    }

    .mainContainerInside {
        margin-top: 90px;
    }

    .gallery img {
        height: 300px;
    }

    .searchButton {
        margin-top: 10px;
    }


}