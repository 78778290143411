@use "./src/base";

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    width: 90%;
    max-width: 600px;
    margin: 50px;
    background-color: white;
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 50px;
}


.loginHeaderImage {
    align-self: center;
    width: 300px;
    height: 300px;
}

.loginTitle {
    text-align: center;
    margin: 0px;
    margin-bottom: 20px;
    font-size: 42px;
}

.forgotPassword{
    color: base.$darkPrimary;
    cursor: pointer;
    width: auto;
    margin-left: 10px;
}

.loginBottonButton {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.primaryButton {
    margin-top: 40px;
    border-radius: 30px;
    height: 60px;
    border: 0px solid;
    color: white;
    background-color:  base.$primary;
    font-size: 24px;
}

.primaryButton:active {
    background-color:  base.$darkPrimary;
}

.loginButtonHeaderColumn{
    width: 90%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin-top: 20px;
}

.loginButtonHeader{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.welcomeTextContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.appNameTitleIcon{
    height: 100px;
    width: 100px;
}

.welcomeTextContainer h2{
    color: white;
}

.welcomeTextContainer h1{
    color: white;
    font-size: 60px;
}

.whiteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 0px solid;
    color: base.$primary;
    background-color: white;
    font-size: 20px;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.whiteButton i{
    font-size: 16px;
    margin-left: 10px;
}

.createAccountText {
    width: 80%;
    font-size: 22px;
    margin: 0;
    font-weight: 400;
    color:  base.$darkText;
    padding-top: 15px;
    text-align: center;
    align-self: center;
}

.invitedText {
    font-size: 16px;
    margin: 0;
    margin-bottom: 20px;
    align-self: flex-end;
    font-weight: 400;
    color: base.$darkPrimary;
    padding-top: 15px;
    text-align: center;
    cursor: pointer;
}

.primaryButton:hover {
    cursor: pointer;
}

.createAccountText:hover {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {


    .loginTitle {
        font-size: 28px;
    }

    .loginHeaderImage {
        width: 200px;
        height: 200px;
    }

    .createAccountText {
        font-size: 16px;
    }

    .invitedText {
        font-size: 12px;
    }
    

    .primaryButton {
        font-size: 16px;
        height: 45px;
        margin-top: 25px;
    }

    .whiteButton {
        font-size: 16px;
        margin-top: 20px;
    }

    .loginButtonHeader{
        flex-direction: column;
    }

    .welcomeTextContainer h2{
        text-align: center;
    }
    .welcomeTextContainer h1{
        font-size: 40px;
        text-align: center;
    }

    .forgotPassword{
       font-size: 12px;
    }
}