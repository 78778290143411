@use "../../../base";

.iconColor {
    color: base.$lightGray;
    align-self: center;
    margin: 0px;
    margin-bottom: 2px;
    font-size: 22px;
}

.inputOutside {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.inputTitle {
    font-size: 22px;
    margin: 0;
    margin-left: 5px;
    font-weight: 400;
    color: base.$darkText;
    margin-bottom: 5px;
}

.inputText {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0px solid;
    border-bottom: 2px solid base.$lightGray;
}

.inputText input {
    font-size: 22px;
    font-weight: 300;
    color: base.$darkText;
    padding: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    border: 0px solid;
    margin-bottom: 2px;
    width: 100%;
}

.inputText input:focus {
    outline: none;
}

.inputText textarea {
    font-size: 22px;
    font-weight: 300;
    color: base.$darkText;
    padding: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    border: 0px solid;
    margin-bottom: 2px;
    width: 100%;
    resize: none;
    height: fit-content;
}

.inputText textarea:focus {
    outline: none;
}

@media only screen and (max-width: 600px) {

    .inputText input {
        font-size: 16px;
    }

    .inputText textarea {
        font-size: 16px;
    }

    .inputTitle {
        font-size: 16px;
    }

    .iconColor {
        font-size: 20px;
    }

    .inputOutside {
        margin-bottom: 20px;
    }

}