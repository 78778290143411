@use "../../../base";

.alertContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin: 0px;
    font-size: 26px;
    margin-top: 20px;
    text-align: center;
}

.description {
    font-size: 22px;
    margin: 0px;
    text-align: center;
    margin-top: 6px;
    font-weight: 500;
    margin-bottom: 10px;
}

.alertContainer i {
    font-size: 70px;
    margin-top: 40px;
    margin-bottom: 35px;
}

.iconBlue{
    color: base.$alertIconColor;
}

.iconRed{
    color: base.$red;
}

.loginBottonButton {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
}

.primaryButton {
    border-radius: 25px;
    height: 30px;
    border: 0px solid;
    color: white;
    background-color: base.$primary;
    height: 50px;
    font-size: 22px;
}

.primaryButton:active {
    border-radius: 25px;
    border: 0px solid;
    color: white;
    background-color: base.$darkPrimary;
}

.primaryButton:hover {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {


    .title {
        font-size: 20px;
    }
    
    .description {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .primaryButton {
        font-size: 16px;
        height: 45px;
    }

    .alertContainer i {
        font-size: 70px;
        margin-top: 40px;
        margin-bottom: 35px;
    }

}