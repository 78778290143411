@use "./src/base";

.carouselContainer {
    width: 90%;
    max-width: 600px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 60px;
}

.mainContainerInsideNoBackground {
    margin-bottom: 20px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.titleWhite {
    text-align: center;
    margin: 0px;
    margin-bottom: 30px;
    color: white;
    font-size: 38px;
    background-color: base.$primary;
    border-radius: 25px;
}

.mainContainerInsideNoBackground p {
    color: white;
    margin-top: 0px;
    background-color: base.$primary;
    border-radius: 25px;
    height: 80px;
}

.mainContainerInsideNoBackground img {
    margin-bottom: 40px;
    border-radius: 25px;
    height: 400px;
    object-fit: contain;
}

@media only screen and (max-width: 600px) {

    .titleWhite {
        font-size: 28px;
    }

    .mainContainerInsideNoBackground img {
        height: 200px;
    }

}