@use "../../../base";

.alertContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    align-items: center;
    margin-bottom: 20px;
}

.headerContainerTitle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: fit-content;
    align-items: center;
}

.headerContainerTitle i {
    color: base.$primary;
}

.xmark{
    font-size: 25px;
    cursor: pointer;
}

.shareContainerr{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
}