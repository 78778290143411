@use "../../../base";

.out {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}

.image {
    margin-top: 0px;
    margin-bottom: 40px;
    max-height: 25px;
    cursor: pointer;
    background-color: base.$primary;
}

.a {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {

    .image {
        max-height: 20px;
        cursor: pointer;
    }

}