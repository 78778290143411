@use "./src/base";

.mainContainer {
    margin-top: 30px;
    width: 100%;
    z-index: 100;
}

.dogCardContainer {
    margin-left: "auto";
    margin-right: "auto";
    width: 150px;
    height: 250px;
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 1px 1px;
    position: relative;
    transition: 0.3s;

    img {
        width: 150px;
        height: 250px;
        object-fit: cover;
        position: absolute;
        z-index: 99;
        transition: 0.3s;
    }

    .iconText {
        z-index: 100;
        position: absolute;
        bottom: 20px;
        right: 20px;

        i {
            font-size: 20px;
            color: white;
            text-shadow: 3px 3px 10px rgb(78, 78, 78);
        }

    }
}

.dogCardContainer:hover {
    border-radius: 10px;
    transition: 0.3s;

    img {
        width: 170px;
        height: 270px;
        transition: 0.3s;
    }

}