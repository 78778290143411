@use "./src/base";

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    margin-top: 90px;
    width: 90%;
    max-width: 600px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.myPetsHeader {
    background-color: white;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    cursor: pointer;
}

.myPetsHeader i {
    margin: 0px;
    font-size: 30px;
}

.myPetsHeader h3 {
    margin: 0px;
    font-size: 30px;
}

.petsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.petsCellContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 25px;
    padding: 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 15px;
}

.petsCellContainer i {
    color: base.$lightGray;
    align-self: center;
    margin: 0px;
    margin-bottom: 2px;
    width: 20px;
    font-size: 30px;
}

.petsCellTagsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    margin-top: 5px;
}

.petsCellTagsContainer p {
    color: base.$darkText;
    font-size: 22px;
    margin-left: 30px;
    font-weight: 300;
}

.petsCellQrContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 10px;
    cursor: pointer
}

.petsCellQrContainer p {
    color: base.$darkText;
    font-size: 20px;
    margin: 0px;
    margin-left: 5px;
    font-weight: 300;
    margin-right: 5px;
}

.petsCellQrContainer i {
    color: base.$primary;
    margin: 0px;
}

.petsCellTitleImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

}

.petImageContainer {
    padding: 1px;
    margin-right: 5px;
    border-style: dashed;
    border-color: base.$primary;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.petImage {
    margin: 0px;
    border-radius: 100px;
    height: 60px;
    width: 60px;
    object-fit: cover;
}

.petsCellTitleImage h5 {
    font-size: 24px;
}


.lookDogs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: 2px solid white;
    border-radius: 25px;
    color: white;
    padding: 10px 20px;
    width: max-content;
    cursor: pointer;
    gap: 20px;
    font-size: 12px;
    align-self: flex-end;
}


.lookDogs .movei {
    margin-left: 0px;
    margin-right: 10px;
    transition: 0.3s;
}

.lookDogs:hover .movei {
    margin-right: 0px;
    margin-left: 10px;
    transition: 0.3s;
}


.lookDogs img {
    margin-left: 10px;
    width: 20px;
    height: 20px;
}

.sectionTitle {
    color: white;
    margin-top: 60px;
    margin-bottom: 10px;
    text-align: center;
}

.sectionSubTitle {
    color: white;
    margin-bottom: 30px;
    text-align: center;
}

@media only screen and (max-width: 600px) {


    .myPetsHeader h3 {
        font-size: 22px;
    }

    .petsCellTitleImage h5 {
        font-size: 22px;
    }

    .petsCellContainer p {
        font-size: 22px;
        margin-left: 0px;
    }

    .petsCellContainer i {
        font-size: 22px;
        margin-right: 20px;
    }

    .petsCellQrContainer p {
        font-size: 14px;
    }

    .petsCellQrContainer i {
        font-size: 22px;
        margin-right: 10px;
    }

    .mainContainerInside {
        margin-top: 90px;
    }


}