@use "./src/base";

.mainContainer {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    width: 90%;
    max-width: 600px;
    margin-bottom: 20px;
    background-color: white;
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mainContainerInsideNoBackground {
    width: 90%;
    max-width: 600px;
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleWhiteFirst {
    text-align: center;
    margin: 0px;
    margin-bottom: 40px;
    margin-top: 140px;
    color: white;
    font-size: 38px;
}

.titleWhite {
    text-align: center;
    margin: 0px;
    margin-bottom: 40px;
    color: white;
    font-size: 38px;
    margin-top: 50px;
}

.titleWhiteH3 {
    width: 100%;
    text-align: left;
    margin: 0px;
    color: white;
    margin-bottom: 5px;
    font-size: 26px;
}

.iconColor {
    color: base.$lightGray;
    margin-bottom: 2px;
}

.mainContainerInsideNoBackground p {
    color: white;
    margin-top: 0px;
    font-size: 22px;
}

.mainContainerInsideNoBackground img {
    margin-top: 20px;
    margin-bottom: 40px;
    max-height: 200px;
    cursor: pointer;
}

.mainContainerInsideNoBackground a img {
    margin-top: 20px;
    margin-bottom: 40px;
    max-height: 200px;
    cursor: pointer;
}

.mainContainerInsideNoBackground a {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
}


.appIcon {
    width: 40%;
    max-height: 150px;
    margin-left: 30px;
}

.appIconDog {
    width: 40%;
    max-height: 150px;
    margin-right: 0px;
}

.appIconPlus {
    font-size: 50px;
    color: base.$alertIconColor;
}

.moncake {
    max-width: 300px;
}

@media only screen and (max-width: 600px) {


    .titleWhite {
        font-size: 28px;
    }

    .titleWhiteH3 {
        font-size: 22px;
    }

    .mainContainerInsideNoBackground img {
        max-height: 200px;
    }

    .mainContainerInsideNoBackground a img {
        max-height: 200px;
    }

    .appIcon {
        width: 40%;
        max-height: 100px;
        margin-left: 20px;
    }

    .appIconDog {
        width: 40%;
        max-height: 100px;
        margin-right: 0px;
    }

}