@use "../../../base";

.iconColor {
    color: base.$primary;
    align-self: center;
    margin: 0px;
    text-align: center;
    margin-right: 20px;
    font-size: 30px;
}

.inputImageOutside {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inputInside {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-style: dashed;
    border-color: base.$primary;
    border-radius: 10px;
    width: 100%;
    height: 100px;

}

.hideInput {
    visibility: "hidden";
    width: 0px;
    height: 0px;
    position: absolute;
}

.inputText {
    font-size: 22px;
    color: base.$primary;
    width: auto;
}

.inputTextHint {
    font-size: 18px;
    color: base.$darkText;
    width: auto;
}

.insideTextsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {

    .iconColor {
        font-size: 20px;
    }

    .inputText {
        font-size: 16px;
    }

    .inputTextHint {
        font-size: 12px;
    }

    .inputImageOutside {
        margin-bottom: 20px;
    }


}