@use "./src/base";

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    margin-top: 90px;
    width: 90%;
    max-width: 600px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}


.petsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.petsCellContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 25px;
    padding: 30px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 15px;
}


.petsCellTagsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    margin-bottom: 10px;
    align-items: center;
}

.petsCellTagsContainer p {
    color: base.$darkText;
    font-size: 20px;
    margin-left: 15px;
    font-weight: 300;
}

.petsCellTagsContainer i {
    color: base.$lightGray;
    align-self: center;
    margin: 0px;
    margin-bottom: 2px;
    font-size: 20px;
}

.postTitle {
    font-size: 24px;
    margin-right: 20px;
}

.postDescription {
    margin-top: 20px;
    margin-bottom: 20px;
}

.gallery {
    margin-top: 20px;
    background-color: black;
    border-radius: 10px;
    overflow: hidden;
}

.gallery img {
    width: 100%;
    height: 400px;
    overflow: hidden;
    object-position: center center;
}

.postTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.postTitleContainer i {
    font-size: 22px;
    color: base.$darkPrimary;
    cursor: pointer;
}

.emptyContainer {
    margin-top: 200px;
    margin-bottom: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-size: 24px;
    background-color: base.$primary;
    border-radius: 25px;
}

.emptyContainer h2 {
    text-align: center;
}

.emptyContainer i {
    font-size: 50px;
    margin-bottom: 15px;
}


//comments
.commentsContainer {
    display: flex;
    flex-direction: column;
    color: white;
    margin-top: 30px;

    h1 {
        margin-bottom: 20px;
    }


    .commentListContainer {
        margin-top: 30px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        gap: 20px;


        .commentItemContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            i{
                cursor: pointer;
            }

            .comment {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                pre {
                    font-weight: 200;
                    background-color: base.$primary;
                    border-radius: 25px;
                }

                h3 {
                    background-color: base.$primary;
                    border-radius: 25px;
                }

            }
        }
    }
}


.shareIcon{
    justify-self: flex-end;
    align-self: flex-end;
    cursor: pointer;
    font-size: 30px;
    color: base.$darkPrimary;
}





@media only screen and (max-width: 600px) {


    .postTitle {
        font-size: 22px;
    }

    .petsCellTagsContainer p {
        font-size: 18px;
        margin-left: 0px;
    }

    .petsCellTagsContainer i {
        font-size: 18px;
        margin-right: 10px;
    }

    .mainContainerInside {
        margin-top: 90px;
    }

    .gallery img {
        height: 300px;
    }

}