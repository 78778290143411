@use "./src/base";

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    width: 70%;
    max-width: 500px;
    margin: 50px;
    background-color: white;
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
}

.loginHeaderImage {
    align-self: center;
    width: 100px;
    height: 100px;
}

.loginTitle {
    text-align: center;
    margin: 0px;
    margin-top: 20px;
}

.loginBottonButton {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.primaryButton {
    border-radius: 25px;
    height: 30px;
    border: 0px solid;
    color: white;
    background-color: base.$primary;
}

.primaryButton:active {
    border-radius: 25px;
    height: 30px;
    border: 0px solid;
    color: white;
    background-color: base.$darkPrimary;
}

.createAccountText {
    width: 80%;
    font-size: 12px;
    margin: 0;
    font-weight: 400;
    color: base.$darkText;
    padding-top: 15px;
    text-align: center;
    align-self: center;
}

.primaryButton:hover {
    cursor: pointer;
}

.createAccountText:hover {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {


    .loginTitle {
        font-size: 28px;
    }

    .loginHeaderImage {
        width: 150px;
        height: 150px;
    }

    .createAccountText {
        font-size: 16px;
    }

    .primaryButton {
        font-size: 16px;
        height: 45px;
    }

    .petsCellQrContainer p {
        font-size: 14px;
    }

    .petsCellQrContainer i {
        font-size: 22px;
        margin-right: 10px;
    }

    .petImageDelete {
        width: 40px;
        height: 40px;
    }

    .petImageDelete i {
        font-size: 16px;
    }

    .mainContainerInside {
        margin-top: 90px;
    }

}