@use "../../../base";


.inputOutside {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.inputText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: base.$primary;
    border-radius: 100px;
    width: 100%;
}

.inputText textarea {
    font-size: 18px;
    font-weight: 300;
    color: white;
    padding: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    border: 0px solid;
    margin-bottom: 2px;
    width: 100%;
    border-bottom: 2px solid base.$lightGray;
    background: none;
    resize: none;
    overflow:hidden
}

.inputText textarea::placeholder {
    color: rgba(255, 255, 255, 0.692);
}

.inputText textarea:focus {
    outline: none;
}

.commentButton {
    width: 40px;
    height: 35px;
    display: flex;
    gap: 10px;
    background-color: white;
    border-radius: 25px;
    color: base.$darkText;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-width: 600px) {

    .inputOutside {
        margin-bottom: 20px;
    }

}