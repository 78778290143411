@use "./base";
@import "~react-image-gallery/styles/css/image-gallery.css";

*{
  font-family:"Roboto";
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,p{
  display: inline-block;
  word-break: break-word;
}

.fullscreen .image-gallery-slide img {
  height: 100vh;
}

body {
  margin: 0px;
  background-color: base.$primary;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.appIconBackground{
  height: 100px;
  width: 100px;
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: -1000;
}