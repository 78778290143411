@use "./src/base";

.mainContainer {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    width: 90%;
    max-width: 600px;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: white;
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.loginTitle {
    text-align: center;
    width: 90%;
    max-width: 400px;
    margin: 0px;
    margin-bottom: 0px;
    margin-top: 20px;
    color: white;
    font-size: 38px;
    margin-bottom: 20px;
}

.iconColor {
    color: base.$lightGray;
    margin-bottom: 2px;
}

.inputOutside {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.inputTitle {
    font-size: 12px;
    margin: 0;
    margin-left: 5px;
    font-weight: 400;
    color: base.$darkText;
    margin-bottom: 5px;
}

.inputText {
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0px solid;
    border-bottom: 2px solid base.$lightGray;
}

.inputText input {
    font-size: 12px;
    font-weight: 200;
    color: base.$darkText;
    padding: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    border: 0px solid;
    margin-bottom: 2px;
    width: 100%;
}

.inputText input:focus {
    outline: none;
}

.loginBottonButton {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.primaryButton {
    border-radius: 25px;
    height: 30px;
    border: 0px solid;
    color: white;
    background-color: base.$primary;
}

.primaryButton:active {
    border-radius: 25px;
    height: 30px;
    border: 0px solid;
    color: white;
    background-color: base.$darkPrimary;
}

.createAccountText {
    width: 80%;
    font-size: 12px;
    margin: 0;
    font-weight: 400;
    color: base.$darkText;
    padding-top: 15px;
    text-align: center;
    align-self: center;
}

.primaryButton:hover {
    cursor: pointer;
}

.createAccountText:hover {
    cursor: pointer;
}

.petsCellQrContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.petsCellQrContainer p {
    color: base.$darkText;
    font-size: 10px;
    margin: 0px;
    margin-left: 5px;
    font-weight: 300;
    margin-right: 5px;
}

.petsCellQrContainer i {
    color: base.$primary;
    margin: 0px;
}

.petImageContainer {
    margin-bottom: 20px;
    position: relative;
    padding: 1px;
    border-style: dashed;
    border-color: base.$primary;
    border-radius: 10px;
    display: flex;
}

.petImage {
    object-fit: cover;
    width: 100%;
    margin: 0px;
    border-radius: 10px;
}

.firstTitle {
    margin-top: 140px;
}


@media only screen and (max-width: 600px) {


    .loginTitle {
        font-size: 28px;
    }

    .createAccountText {
        font-size: 16px;
    }

    .primaryButton {
        font-size: 16px;
        height: 45px;
    }

    .petsCellQrContainer p {
        font-size: 14px;
    }

    .petsCellQrContainer i {
        font-size: 22px;
        margin-right: 10px;
    }

    .firstTitle {
        margin-top: 90px;
    }

}