@use "./src/base";

.mainContainer {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    width: 90%;
    max-width: 600px;
    margin-top: 90px;
    margin-bottom: 20px;
    background-color: white;
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.mainContainerInsideNoBackground {
    width: 90%;
    max-width: 600px;
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.loginTitle {
    text-align: center;
    margin: 0px;
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 36px;
}

.titleWhite {
    text-align: center;
    margin: 0px;
    margin-bottom: 40px;
    color: white;
    font-size: 38px;
}

.titleWhiteH3 {
    text-align: left;
    margin: 0px;
    color: white;
    margin-bottom: 5px;
    font-size: 26px;
}

.mainContainerInsideNoBackground p {
    font-size: 22px;
}

.iconColor {
    color: base.$lightGray;
    margin-bottom: 2px;
}

.inputOutside {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.inputText {
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0px solid;
    border-bottom: 2px solid base.$lightGray;
}

.inputText input {
    font-size: 12px;
    font-weight: 200;
    color: base.$darkText;
    padding: 15px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    border: 0px solid;
    margin-bottom: 2px;
    width: 100%;
}

.inputText input:focus {
    outline: none;
}

.loginBottonButton {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.primaryButton {
    border-radius: 30px;
    height: 60px;
    border: 0px solid;
    color: white;
    background-color: base.$primary;
    font-size: 24px;
}

.primaryButton:active {
    border-radius: 25px;
    border: 0px solid;
    color: white;
    background-color: base.$darkPrimary;
}

.createAccountText {
    width: 80%;
    font-size: 22px;
    margin: 0;
    font-weight: 400;
    color: base.$darkText;
    padding-top: 15px;
    text-align: center;
    align-self: center;
}

.primaryButton:hover {
    cursor: pointer;
}

.createAccountText:hover {
    cursor: pointer;
}

.qrHint {
    font-size: 22px;
    text-align: center;
    color: base.$darkText;
    margin-top: 20px;
    margin-bottom: 20px;
}

.mainContainerInsideNoBackground p {
    color: white;
    margin-top: 0px;
}

.gallery{
    margin-top: 20px;
    background-color: white;
    padding: 10px;
    border-radius: 25px;
}

.exampleText{
    margin-top: 50px !important;
}

.plateImage {
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 25px;
    max-height: 200px;
    background-color: white;
}

.youtube{
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
    height: 200px;
}

@media only screen and (max-width: 600px) {


    .loginTitle {
        font-size: 28px;
    }

    .titleWhite {
        font-size: 28px;
    }

    .titleWhiteH3 {
        font-size: 22px;
    }

    .createAccountText {
        font-size: 16px;
    }

    .primaryButton {
        font-size: 16px;
        height: 45px;
    }

    .qrHint {
        font-size: 16px;
    }

    .mainContainerInside {
        margin-top: 90px;
    }

}