@use "./src/base";

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainContainerInside {
    width: 90%;
    max-width: 600px;
    margin: 50px;
    background-color: white;
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.loginHeaderImage {
    align-self: center;
    width: 300px;
    height: 300px;
}

.loginTitle {
    text-align: center;
    margin: 0px;
    margin-bottom: 20px;
    font-size: 42px;
}

.loginBottonButton {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.primaryButton {
    border-radius: 30px;
    height: 60px;
    border: 0px solid;
    color: white;
    background-color: base.$primary;
    font-size: 24px;
}

.primaryButton:active {
    background-color: base.$darkPrimary;
}

.createAccountText {
    width: 80%;
    font-size: 22px;
    margin: 0;
    font-weight: 400;
    color: base.$darkText;
    padding-top: 15px;
    text-align: center;
    align-self: center;
}

.primaryButton:hover {
    cursor: pointer;
}

.createAccountText:hover {
    cursor: pointer;
}

.hintData{
    margin-bottom: 20px;
    text-align: center;
    color: base.$darkText;
}

@media only screen and (max-width: 600px) {


    .loginTitle {
        font-size: 28px;
    }

    .loginHeaderImage {
        width: 200px;
        height: 200px;
    }

    .createAccountText {
        font-size: 16px;
    }

    .primaryButton {
        font-size: 16px;
        height: 45px;
    }

}