@use "../../../base";

.header {
    width: 100%;
    max-width: 600px;
    margin-bottom: 0px;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.headerColum {
    width: 90%;
    max-width: 600px;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1001;
}

.headerFullBar {
    position: fixed;
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    box-shadow: 1px 1px 5px base.$darkText;
}

.iconsGroup{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.userIconContainer {
    background-color: white;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}


.userIconContainerShelter {
    background-color: base.$darkPrimary;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    color: white;
    gap: 10px;
    cursor: pointer;
    transition: 0.3s;

    i{
        font-size: 16px;
    }

    p{
        font-size: 14px;
        font-weight: 600;
    }

}

.userIconContainerShelter:hover {
    background-color: base.$primary;
    transition: 0.3s;
}

.userIcon {
    margin: 0px;
    padding: 0px;
    color: base.$primary;
}

.menuIcon {
    margin: 0px;
    padding: 0px;
    color: base.$primary;
}

.menuContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.menuContainer ul {
    margin: 0px;
    background-color: white;
    border-radius: 25px;
    width: 100%;
    max-width: 300px;
    list-style: none;
    padding: 10px;
    padding-top: 0px;
    padding-left: 13px;
}

.menuContainer ul a {
    width: 100%;
    text-decoration: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: 6px;
    border-bottom: 1px solid base.$lightGray;
    padding-bottom: 5px;
    transition: 0.2s;
}

.menuContainer ul a i {
    margin: 0px;
    padding: 0px;
    color: base.$primary;
    font-size: 20px;
    margin-right: 5px;
}

.menuContainer ul a li {
    margin: 0px;
    padding: 0px;
    padding-left: 5px;
    color: base.$darkText;
    font-size: 20px;
}

.menuContainer ul a:hover {
    padding-left: 10px;
    transition: 0.2s;
}

.menuContainerBackground {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
}

.out {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    z-index: 900;
}

@keyframes smooth-appear {
    to {
        opacity: 1;
    }
}

@keyframes smooth- {
    to {
        opacity: 1;
    }
}

.userIconContainerAnimated {
    opacity: 0;
    animation: smooth-appear 0.3s ease forwards;
}

@media only screen and (max-width: 600px) {


    .menuContainer ul a li {
        font-size: 20px;
    }

    .menuContainer ul {
        max-width: 300px;
    }

    .menuContainer ul a i {
        font-size: 20px;
        margin-right: 5px;
    }

    .userIconContainer {
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

}